import React from "react";
import Link from "./link";

export default ({ children, ...props }) => {
    return (
        <Link
            className="inline-block py-1 text-base font-bold no-underline border-b-2 border-current text-dark hover:text-opacity-80 hover:no-underline"
            {...props}
        >
            {children}
        </Link>
    );
};
