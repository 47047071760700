import React from "react";
import { Link as GatsbyLink } from "gatsby";

export default ({ children, to, ...props }) => {
    const internal = /^\/(?!\/)/.test(to);

    if (internal) {
        return (
            <GatsbyLink to={to} {...props}>
                {children}
            </GatsbyLink>
        );
    }

    return (
        <a href={to} target="_blank" rel="noopener noreferrer" {...props}>
            {children}
        </a>
    );
};
