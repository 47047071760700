import "swiper/css";
import "swiper/css/scrollbar";
import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import AccessoryLink from "~/accessory-link";
import BlockContent from "~/block-content";
import Column from "~/column";
import ContactUs from "~/contact-us";
import Container from "~/container";
import Layout from "~/layout";
import SEO from "~/seo";
import ArrowLeft from "@/images/icon-arrow-left.inline.svg";

const ProductTemplate = ({ data }) => {
    const product = data.product;

    return (
        <Layout>
            <div className="relative">
                <Container className="relative mt-section">
                    <Column className="absolute top-0 -mt-8 lg:-mt-10">
                        <a
                            className="flex no-underline group shrink-0 hover:no-underline"
                            href="/sale"
                        >
                            <ArrowLeft className="content-end self-center w-3 h-3 mb-3 border-b-2 border-transparent" />
                            <span className="pb-2 ml-2 text-base font-bold border-b-2 border-transparent group-hover:border-black">
                                Zurück
                            </span>
                        </a>
                    </Column>
                    <Column className="lg:grid lg:gap-xl lg:grid-cols-4">
                        <header className="lg:col-span-3">
                            <h1 className="my-0 text-5xl leading-normal">{product.title}</h1>
                            {product.price && (
                                <div className="mt-0 mb-3 text-3xl leading-normal md:text-4xl lg:text-5xl font-regular">
                                    EUR {new Intl.NumberFormat("de-DE").format(product.price)}
                                </div>
                            )}
                            {product.condition && <span>{product.condition}</span>}
                        </header>
                    </Column>
                </Container>
            </div>

            {product.images && (
                <div className="relative z-20 overflow-hidden">
                    <Container className="my-gutter">
                        <Column className="-m-1 md:-p-2 lg:-p-3 2xl:-p-4">
                            <Swiper
                                modules={[Scrollbar]}
                                style={{ overflow: "visible" }}
                                spaceBetween={16}
                                freeMode={true}
                                grabCursor={true}
                                mousewheel={{ forceToAxis: true }}
                                scrollbar={{ draggable: true }}
                                slidesPerView="auto"
                                passiveListeners={true}
                            >
                                {product.images.map((image, index) => (
                                    <SwiperSlide
                                        className="max-w-2xl p-1 md:p-2 lg:p-3 2xl:p-4 group"
                                        key={`gallery-${index}`}
                                    >
                                        <GatsbyImage
                                            className="h-full rounded-xl -z-10"
                                            image={image.asset.gatsbyImage}
                                            alt={image.alt || ""}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Column>
                    </Container>
                </div>
            )}

            {product._rawBody && (
                <Container className="my-3xl">
                    <Column className="lg:flex">
                        <div className="max-w-2xl lg:mr-14">
                            <h4>Beschreibung</h4>
                            <BlockContent blocks={product._rawBody} />
                            {product.href && (
                                <AccessoryLink to={product.href}>
                                    Weitere Informationen
                                </AccessoryLink>
                            )}
                        </div>
                        <button className="self-start py-4 font-bold text-white bg-black mt-xl md:min-w-lg rounded-xl px-xl justify-self-stretch hover:opacity-80">
                            <a
                                className="no-underline hover:no-underline"
                                href={`mailto:info@bueroschriever.de?subject=Anfrage - ${product.title}`}
                            >
                                Jetzt anfragen
                            </a>
                        </button>
                    </Column>
                </Container>
            )}

            <Container>
                <Column>
                    <ContactUs className="flex items-center max-w-2xl space-x-md md:space-x-lg" />
                </Column>
            </Container>
        </Layout>
    );
};

export const Head = ({ data }) => <SEO title={data.product.title} description="" />;

export default ProductTemplate;

export const query = graphql`
    query ProductTemplateQuery($id: String!) {
        product: sanityProduct(id: { eq: $id }, visible: { eq: true }) {
            id
            title
            price
            condition
            _rawBody
            images {
                alt
                asset {
                    gatsbyImage(width: 720, height: 720, fit: COVER, placeholder: BLURRED)
                    url
                }
            }
        }
    }
`;
