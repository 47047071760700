import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default ({ name, label, ...props }) => {
    return (
        <StaticQuery
            query={query}
            render={(data) => (
                <div
                    className="flex items-center max-w-md mx-auto space-x-md md:space-x-lg"
                    {...props}
                >
                    <figure className="w-1/4">
                        <GatsbyImage
                            image={getImage(data.image)}
                            alt="Portrait von Lutz Schriever"
                            title="Lutz Schriever"
                            className="relative overflow-hidden transform rounded-full"
                        />
                    </figure>
                    <div>
                        <h3 className="my-0 text-inherit">Wünschen Sie Beratung?</h3>
                        <ul className="my-2">
                            <li>
                                <a href="tel:004923536665466">+49 (0) 2353 666 54 66</a>
                            </li>
                            <li>
                                <a href="mailto:info@bueroschriever.de">info@bueroschriever.de</a>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
        />
    );
};

const query = graphql`
    query ContactUsQuery {
        image: file(relativePath: { eq: "lutz-schriever.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 200, layout: CONSTRAINED)
            }
        }
    }
`;
